<template>
  <b-modal
    id="modal-activity"
    :visible="showModal"
    centered
    hide-footer
    :title="getModalTitle"
    @hide="resetModal"
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <!-- schedule type: lesson -->
        <template v-if="isLesson">
          <basic-select
            label="Loại bài học"
            placeholder="--- Chọn loại bài học ---"
            :options="lessonTypes"
            value-label="text"
            track-by="value"
            changeValueByObject
            :value.sync="form.type"
            @update:value="filterLesson"
          />
          <basic-select
            label="Chủ đề"
            placeholder="--- Chọn chủ đề ---"
            :options="moduleList"
            value-label="text"
            track-by="value"
            changeValueByObject
            :value.sync="form.module"
            @update:value="filterLesson"
          />
          <basic-select
            label="Cấp độ"
            placeholder="--- Chọn cấp độ ---"
            :options="levelList"
            value-label="text"
            track-by="value"
            changeValueByObject
            :value.sync="form.level"
            @update:value="filterLesson"
          />
          <basic-select
            label="Bài học"
            placeholder="--- Chọn bài học ---"
            :options="lessonList"
            value-label="name"
            track-by="id"
            changeValueByObject
            required
            name="lesson"
            v-validate="'required'"
            :state="validateState('lesson')"
            :invalidFeedback="errors.first('lesson')"
            data-vv-as="Bài học"
            :value.sync="form.lesson"
          />
        </template>

        <!-- schedule type: common -->
        <template v-if="isCommonActivity">
          <basic-select
            label="Chọn loại hoạt động"
            placeholder="--- Chọn loại hoạt động ---"
            :options="activityTypes"
            value-label="text"
            track-by="value"
            changeValueByObject
            :value.sync="form.activityType"
            :allow-empty="false"
          />
          <basic-select
            v-if="isInteractionGroup"
            label="Chọn tên Hoạt động"
            placeholder="--- Chọn tên Hoạt động ---"
            :options="activities"
            value-label="text"
            track-by="value"
            changeValueByObject
            :value.sync="form.activity"
            :allow-empty="false"
            required
            name="activity"
            v-validate="'required'"
            :state="validateState('activity')"
            :invalidFeedback="errors.first('activity')"
            data-vv-as="Tên Hoạt động"
            @input="$validator.reset({ name: 'activity' })"
          />
          <basic-select
            v-if="isSurveyType"
            label="Chọn khảo sát"
            placeholder="--- Chọn khảo sát ---"
            :options="surveyList"
            value-label="text"
            track-by="value"
            changeValueByObject
            required
            name="survey"
            v-validate="'required'"
            :state="validateState('survey')"
            :invalidFeedback="errors.first('survey')"
            data-vv-as="Khảo sát"
            :value.sync="form.survey"
          />
          <template v-else-if="!isOutputRating && !isUpdateProfile">
            <basic-input
              label="Tên buổi coach"
              placeholder="Nhập tên buổi coach"
              :value.sync="form.name"
              maxlength="40"
              required
              name="name"
              v-validate="'required|max:40'"
              :state="validateState('name')"
              :invalidFeedback="errors.first('name')"
              data-vv-as="Tên buổi coach"
            />
          </template>
        </template>

        <!-- schedule type: free -->
        <template v-if="isFreeActivity">
          <basic-input
            label="Tên hoạt động"
            placeholder="Nhập tên hoạt động"
            required
            name="name"
            v-validate="'required|max:24'"
            :state="validateState('name')"
            :invalidFeedback="errors.first('name')"
            data-vv-as="Tên hoạt động"
            maxlength="24"
            v-model="form.name"
          />
          <basic-input
            label="Số lần trong ngày"
            placeholder="Nhập số lần trong ngày"
            input-number
            maxlength="3"
            v-model="form.timesPerDay"
            name="timesPerDay"
            v-validate="{
              required: !form.timesOfActivity,
              more_than_zero: true,
            }"
            :required="!form.timesOfActivity"
            :state="validateState('timesPerDay')"
            :invalidFeedback="errors.first('timesPerDay')"
            data-vv-as="Số lần trong ngày"
          />
          <h6 class="mb-7">Hoặc</h6>
          <basic-input
            label="Thời gian diễn ra hoạt động"
            placeholder="Nhập thời gian diễn ra hoạt động"
            input-number
            maxlength="3"
            v-model="form.timesOfActivity"
            name="timesOfActivity"
            v-validate="{
              required: !form.timesPerDay,
              more_than_zero: true,
            }"
            :required="!form.timesPerDay"
            :state="validateState('timesOfActivity')"
            :invalidFeedback="errors.first('timesOfActivity')"
            data-vv-as="Thời gian diễn ra hoạt động"
          />
          <basic-select
            label="Chọn các ngày diễn ra hoạt động"
            placeholder="--- Chọn các ngày diễn ra hoạt động ---"
            :options="days"
            multiple
            name="day"
            v-validate="'required'"
            :state="validateState('day')"
            :invalidFeedback="errors.first('day')"
            data-vv-as="Chọn các ngày diễn ra hoạt động"
            :value.sync="form.selectedDays"
            value-label="text"
            track-by="value"
            changeValueByObject
            required
          />
        </template>

        <!-- schedule type: kpi -->
        <template v-if="isKpiActivity">
          <basic-select
            label="Chọn loại hoạt động"
            placeholder="--- Chọn loại hoạt động ---"
            :options="activityTypes"
            value-label="text"
            track-by="value"
            changeValueByObject
            :value.sync="form.activityType"
            :allow-empty="false"
            name="activityType"
            v-validate="'required'"
            :state="validateState('activityType')"
            :invalidFeedback="errors.first('activityType')"
            data-vv-as="Chọn loại hoạt động"
            required
            @update:value="$validator.reset()"
          />
          <template v-if="isMovementType">
            <basic-input
              label="Số lần trong ngày"
              placeholder="Nhập số lần trong ngày"
              input-number
              maxlength="3"
              v-model="form.timesPerDay"
              name="timesPerDay"
              v-validate="{
                required: true,
                more_than_zero: true,
              }"
              :required="true"
              :state="validateState('timesPerDay')"
              :invalidFeedback="errors.first('timesPerDay')"
              data-vv-as="Số lần trong ngày"
            />
            <basic-select
              label="Chọn các ngày diễn ra hoạt động"
              placeholder="--- Chọn các ngày diễn ra hoạt động ---"
              :options="days"
              multiple
              name="day"
              v-validate="'required'"
              :state="validateState('day')"
              :invalidFeedback="errors.first('day')"
              data-vv-as="Chọn các ngày diễn ra hoạt động"
              :value.sync="form.selectedDays"
              value-label="text"
              track-by="value"
              changeValueByObject
              required
            />
          </template>
          <div v-else>
            <basic-input
              label="Số phút vận động mỗi ngày"
              placeholder="Nhập số phút vận động mỗi ngày"
              input-number
              maxlength="3"
              v-model="form.timesOfActivity"
              name="timesOfActivity"
              v-validate="{
                required: true,
                more_than_zero: true,
              }"
              required
              :state="validateState('timesOfActivity')"
              :invalidFeedback="errors.first('timesOfActivity')"
              data-vv-as="Số phút vận động mỗi ngày"
            />
          </div>
        </template>

        <div class="text-right pt-6">
          <b-button class="btn mr-2" type="button" @click="resetModal">
            Hủy
          </b-button>
          <b-button class="btn btn-success" type="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Lưu
          </b-button>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>
<script>
import { SCHEDULE_TYPES } from '@/core/plugins/constants';
import {
  AGENDA_COMMON_TYPE,
  MODAL_TYPE,
} from '../../../../core/plugins/constants';
export default {
  name: 'ModalActivity',
  props: {
    scheduleType: {
      type: Number,
      default: SCHEDULE_TYPES.COMMON,
    },
    agenda: {
      type: Object,
      default: () => {},
    },
    activityId: {
      type: String,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: MODAL_TYPE.CREATE,
    },
  },
  data() {
    return {
      loading: false,
      activityTypes: [],
      form: {
        name: null,

        // general
        activityType: null,
        activity: null,
        survey: null,

        // free
        selectedDays: [],
        timesPerDay: null,
        timesOfActivity: null,

        // lesson
        type: null,
        module: null,
        level: null,
        lesson: null,
      },
      surveyList: [],
      days: [],
      lessonTypes: [],
      moduleList: [],
      levelList: [],
      lessonList: [],
      activityDetail: {},
      activities: [],
    };
  },
  computed: {
    getModalTitle() {
      return `${this.isCreate ? 'Thêm mới' : 'Chỉnh sửa'} ${
        this.isLesson ? 'bài học' : 'hoạt động'
      }`;
    },
    isLesson() {
      return this.scheduleType === SCHEDULE_TYPES.LESSON;
    },
    isCommonActivity() {
      return this.scheduleType === SCHEDULE_TYPES.COMMON;
    },
    isFreeActivity() {
      return this.scheduleType === SCHEDULE_TYPES.FREE;
    },
    isKpiActivity() {
      return this.scheduleType === SCHEDULE_TYPES.KPI;
    },
    isMovementType() {
      return this.isKpiActivity && this.form.activityType?.value !== 5;
    },
    isSurveyType() {
      return (
        this.isCommonActivity &&
        this.form.activityType?.value === AGENDA_COMMON_TYPE.SURVEY
      );
    },
    isOutputRating() {
      return (
        this.isCommonActivity &&
        this.form.activityType?.value === AGENDA_COMMON_TYPE.OUTPUT_RATING
      );
    },
    isUpdateProfile() {
      return (
        this.isCommonActivity &&
        this.form.activityType?.value === AGENDA_COMMON_TYPE.UPDATE_PROFILE
      );
    },
    isInteractionGroup() {
      return (
        this.isCommonActivity &&
        this.form.activityType?.value === AGENDA_COMMON_TYPE.GROUP_INTERACTION
      );
    },
    searchParams() {
      return {
        excludeAgendaId: this.agendaId,
        type: this.form.type?.value,
        lessonLevel: this.form.level?.value,
        lessonModule: this.form.module?.value,
      };
    },
    agendaId() {
      return this.$route.params.id;
    },
    isCreate() {
      return this.type === MODAL_TYPE.CREATE;
    },
  },
  watch: {
    'form.timesPerDay'(val) {
      val && (this.form.timesOfActivity = null);
    },
    'form.timesOfActivity'(val) {
      val && (this.form.timesPerDay = null);
    },
  },
  async created() {
    if (this.activityId) {
      await this.getActivityDetail();
    }
    await this.setupData();
  },
  methods: {
    resetModal() {
      this.$emit('reset');
    },
    async getActivityDetail() {
      try {
        this.loading = true;
        let url = `/Agenda/urlPath/${this.activityId}`;

        if (this.isFreeActivity) {
          url = url.replace('urlPath', 'Free');
        } else if (this.isKpiActivity) {
          url = url.replace('urlPath', 'Kpi');
        } else if (this.isCommonActivity) {
          url = url.replace('urlPath', 'General');
        } else {
          url = url.replace('urlPath', 'Lesson');
        }

        const { meta, data, error } = await this.$api.get(url);

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }

        this.activityDetail = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async setupData() {
      if (this.isCommonActivity) {
        await this.getPrepareGeneral();
        if (!this.isCreate) {
          this.form.activityType = this.activityTypes.find(
            (el) => el.value === this.activityDetail.classify,
          );
          this.form.name = this.activityDetail.name;

          if (this.isSurveyType) {
            this.form.survey = {
              text: this.activityDetail.survey.name,
              value: this.activityDetail.survey.id,
            };
          }
          if (this.isInteractionGroup) {
            this.form.activity = {
              text: this.activityDetail.calendarActiveName,
              value: this.activityDetail.calendarActive,
            };
          }
        }
      }
      if (this.isFreeActivity || this.isKpiActivity) {
        this.days = Array.from(
          { length: this.agenda.scheduleEndDay },
          (_, i) => {
            return {
              text: `Ngày ${i + 1}`,
              value: i + 1,
            };
          },
        );

        if (!this.isCreate) {
          this.form.name = this.activityDetail.name;
          this.activityDetail.timesPerDay &&
            (this.form.timesPerDay = this.activityDetail.timesPerDay);
          this.activityDetail.timesOfActivity &&
            (this.form.timesOfActivity = this.activityDetail.timesOfActivity);
          this.setValueSelectedDays(this.activityDetail.selectedDays);
        }
      }

      this.isKpiActivity && this.setActivityType();

      if (this.isLesson) {
        await this.getPrepareLesson();
        this.form.lesson = {
          id: this.activityDetail.lesson.id,
          name: this.activityDetail.lesson.name,
        };
      }
    },
    setActivityType(isAgendaHasOutputRating, isAgendaHasUpdateProfile) {
      if (this.isKpiActivity) {
        this.activityTypes = [
          {
            text: 'Đo huyết áp',
            value: 1,
          },
          {
            text: 'Đo cân nặng',
            value: 2,
          },
          {
            text: 'Nhập cảm xúc',
            value: 3,
          },
          {
            text: 'Cập nhật bữa ăn',
            value: 4,
          },
          {
            text: 'Vận động',
            value: 5,
          },
        ];

        if (!this.isCreate) {
          this.form.activityType = this.activityTypes.find(
            (el) => el.value === this.activityDetail.classify,
          );
          this.form.timesPerDay = this.activityDetail.timesPerDay;
        } else {
          this.form.activityType = {
            text: 'Đo huyết áp',
            value: 1,
          };
        }
      }

      if (this.isCommonActivity) {
        this.form.activityType = {
          text: 'Tương tác CÁ NHÂN',
          value: AGENDA_COMMON_TYPE.PERSONAL_INTERACTION,
        };
        this.activityTypes = [
          {
            text: 'Tương tác CÁ NHÂN',
            value: AGENDA_COMMON_TYPE.PERSONAL_INTERACTION,
          },
          {
            text: 'Tương tác NHÓM',
            value: AGENDA_COMMON_TYPE.GROUP_INTERACTION,
          },
          {
            text: 'Khảo sát',
            value: AGENDA_COMMON_TYPE.SURVEY,
          },
          {
            text: 'Đánh giá đầu ra',
            value: AGENDA_COMMON_TYPE.OUTPUT_RATING,
          },
          {
            text: 'Cập nhật hồ sơ',
            value: AGENDA_COMMON_TYPE.UPDATE_PROFILE,
          },
        ];

        let deleteIndex = null;

        if (isAgendaHasOutputRating) {
          deleteIndex = this.activityTypes.findIndex(
            (el) => el.value === AGENDA_COMMON_TYPE.OUTPUT_RATING,
          );
          this.activityTypes.splice(deleteIndex, 1);
        }
        if (isAgendaHasUpdateProfile) {
          deleteIndex = this.activityTypes.findIndex(
            (el) => el.value === AGENDA_COMMON_TYPE.UPDATE_PROFILE,
          );
          this.activityTypes.splice(deleteIndex, 1);
        }
      }
    },
    setValueSelectedDays(seletedDays) {
      if (seletedDays && !seletedDays.length) return [];

      seletedDays.forEach((selectedDay) => {
        this.form.selectedDays.push(
          this.days.find((el) => el.value === selectedDay),
        );
      });
    },
    async getPrepareGeneral() {
      try {
        this.loading = true;
        const { meta, data, error } = await this.$api.get(
          '/Agenda/General/PrepareFormItem',
          {
            params: {
              agendaId: this.agendaId,
            },
          },
        );

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.setActivityType(
          data.isAgendaHasOutputRating,
          data.isAgendaHasUpdateProfile,
        );
        this.surveyList = data.surveyList;
        this.activities = data.calendarActive;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async getPrepareLesson() {
      try {
        this.loading = true;
        const { meta, data, error } = await this.$api.get(
          '/Agenda/Lesson/PrepareFormItem',
        );

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.lessonTypes =
          data.typeList.map((el) => ({
            ...el,
            value: Number(el.value),
          })) || [];
        this.moduleList = data.moduleList || [];
        this.levelList = data.levelList || [];
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    filterLesson() {
      this.form.lesson = null;
      this.searchLesson();
    },
    async searchLesson(loading = true) {
      if (!this.form.type && !this.form.level && !this.form.module)
        return (this.lessonList = []);

      try {
        loading && (this.loading = true);
        const { meta, data, error } = await this.$api.get(
          '/Agenda/Lesson/SearchItem',
          {
            params: this.searchParams,
          },
        );

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.lessonList = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        loading && (this.loading = false);
      }
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit();
          return;
        }
      });
    },
    submit() {
      if (!this.isCreate) {
        if (this.isCommonActivity) return this.updateAgendaGeneral();
        if (this.isFreeActivity) return this.updateAgendaFree();
        if (this.isKpiActivity) return this.updateAgendaKpi();
        this.updateAgendaLesson();
        return;
      }

      if (this.isCommonActivity) return this.createAgendaGeneral();

      if (this.isFreeActivity) return this.createAgendaFree();

      if (this.isKpiActivity) return this.createAgendaKpi();

      this.createAgendaLesson();
    },
    async createAgendaGeneral() {
      try {
        this.loading = true;
        const payload = {
          agendaId: this.agendaId,
          classify: this.form.activityType.value,
          week: this.agenda.value,
        };

        if (this.form.activity?.value) {
          payload['calendarActivity'] = Number(this.form.activity.value);
        }

        if (this.form.survey?.value) {
          payload['surveyId'] = this.form.survey.value;
        } else if (this.form.activityType.value !== 4) {
          payload['name'] = this.form.name;
        }
        const { meta, error } = await this.$api.post(
          '/Agenda/General',
          payload,
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.$emit('reload-data');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async updateAgendaGeneral() {
      try {
        this.loading = true;
        const payload = {
          agendaId: this.agendaId,
          classify: this.form.activityType.value,
          week: this.agenda.value,
          id: this.activityDetail.id,
        };

        if (this.form.activity?.value) {
          payload['calendarActivity'] = Number(this.form.activity.value);
        }

        if (this.form.survey?.value) {
          payload['surveyId'] = this.form.survey.value;
        } else if (this.form.activityType.value !== 4) {
          payload['name'] = this.form.name;
        }
        const { meta, error } = await this.$api.put(
          `/Agenda/General/${payload.id}`,
          payload,
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.$emit('reload-data');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async createAgendaFree() {
      try {
        this.loading = true;

        const payload = {
          name: this.form.name,
          agendaId: this.agendaId,
          days: this.form.selectedDays.map((el) => el.value),
        };

        if (this.form.timesPerDay) {
          payload['timesPerDay'] = Number(this.form.timesPerDay);
        }
        if (this.form.timesOfActivity) {
          payload['timesOfActivity'] = Number(this.form.timesOfActivity);
        }
        const { meta, error } = await this.$api.post('/Agenda/Free', payload);

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }

        this.$emit('reload-data');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async updateAgendaFree() {
      try {
        this.loading = true;

        const payload = {
          name: this.form.name,
          agendaId: this.agendaId,
          days: this.form.selectedDays.map((el) => el.value),
          id: this.activityId,
        };

        if (this.form.timesPerDay) {
          payload['timesPerDay'] = Number(this.form.timesPerDay);
        }
        if (this.form.timesOfActivity) {
          payload['timesOfActivity'] = Number(this.form.timesOfActivity);
        }
        const { meta, error } = await this.$api.put(
          `/Agenda/Free/${payload.id}`,
          payload,
        );

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }

        this.$emit('reload-data');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async createAgendaKpi() {
      try {
        this.loading = true;

        const payload = {
          agendaId: this.agendaId,
          classify: this.form.activityType.value,
        };

        if (this.form.timesPerDay) {
          payload['timesPerDay'] = Number(this.form.timesPerDay);
        }
        if (this.form.timesOfActivity) {
          payload['timesOfActivity'] = Number(this.form.timesOfActivity);
        }
        if (this.form.selectedDays.length) {
          payload['days'] = this.form.selectedDays.map((el) => el.value);
        }

        const { meta, error } = await this.$api.post('/Agenda/Kpi', payload);

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }

        this.$emit('reload-data');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async updateAgendaKpi() {
      try {
        this.loading = true;

        const payload = {
          agendaId: this.agendaId,
          classify: this.form.activityType.value,
          id: this.activityId,
        };

        if (this.form.timesPerDay) {
          payload['timesPerDay'] = Number(this.form.timesPerDay);
        }
        if (this.form.timesOfActivity) {
          payload['timesOfActivity'] = Number(this.form.timesOfActivity);
        }
        if (this.form.selectedDays.length) {
          payload['days'] = this.form.selectedDays.map((el) => el.value);
        }

        const { meta, error } = await this.$api.put(
          `/Agenda/Kpi/${payload.id}`,
          payload,
        );

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }

        this.$emit('reload-data');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async createAgendaLesson() {
      try {
        this.loading = true;

        const payload = {
          agendaId: this.agendaId,
          lessonId: this.form.lesson.id,
          week: this.agenda.value,
        };

        const { meta, error } = await this.$api.post('/Agenda/Lesson', payload);

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }

        this.$emit('reload-data');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async updateAgendaLesson() {
      try {
        this.loading = true;

        const payload = {
          agendaId: this.agendaId,
          lessonId: this.form.lesson.id,
          week: this.agenda.value,
          id: this.activityDetail.id,
        };

        const { meta, error } = await this.$api.put(
          `/Agenda/Lesson/${payload.id}`,
          payload,
        );

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }

        this.$emit('reload-data');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang=""></style>
